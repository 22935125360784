import * as React from "react";
import Layout from "../components/layout";
import Top10Posts from "../components/top10Posts";
import { Link } from "gatsby";
import { mainColor, backgroundWhite } from "../shared/layout.module.css";
import { LinkedinIcon } from "react-share";

const IndexPage = () => {
  return (
    <Layout
      pageTitle="Daniel José Da Silva"
      keywords={
        "daniel jose da silva,agile,c#,technologies,curriculum,daniel,silva,blog,daniel josé da silva"
      }
      description={
        "Daniel José Da Silva - Curriculum and Blog about Agile, Scrum and modern Tech Stacks"
      }
    >
      <div className={`p-3 border rounded ${backgroundWhite}`}>
        <h2 className={`${mainColor} pt-2`}>Bio</h2>
        <div>
          <p>
            Daniel José da Silva has 12+ years working as Software Engineer and
            Scrum Master with Bachelor in Information Systems plus experience
            architecting and developing Web, Mobile and Client-Server
            applications. Along of his career he has been working with C#,
            Microsoft SQL Server, ASP.NET, Microsoft MVC, Microsoft Azure, RESP
            APIs, Microsoft Business Intelligence, LINQ, Entity Framework, UML,
            JavaScript, JQuery, GIT, Xamarin Android/Forms, Teamcity, Jenkins,
            Azure DevOps, Agile Methodologies, Scrum and more.
          </p>
          <p>
            During those years Daniel José da Silva has been through several
            projects and industries such as Energy, Recycling, Law firms, Big
            Factories (perfume, assembly lines), Schools, Political Campaigns
            Projects and so one. Each of those projects taught me a lot about
            growing as a professional and as a person. It helped me to become
            self motivated and the most important to learn how to be prepared
            for the future.
          </p>
          <p>
            Agile culture is part of my day to day. I try my best to incorporate
            the best principles and technologies so the teams that I belong to
            are empowered to achieve their goals. I do understand that Agile is
            not just a switch in the terms of whether you are agile or not, it
            all depends on the context of the projects/products that you are
            working and use the best judgement to apply it where it fits. Also I
            am a huge fan of Scrum.
          </p>
          <p>
            In my free time I do a bit of writing on my blog trying to help
            others to learn a new tech or some tricks that I learned working in
            the IT world, also spending quality time with my family as they are
            my biggest motivation to keep evolving and give the best for them.
          </p>
        </div>
        <h2 className={`${mainColor} pt-2`}>Curriculum</h2>
        <div>
          <p>
            Read Daniel José da Silva's full curriculum - resume{" "}
            <Link to="/curriculum">here.</Link>
          </p>
        </div>
        <h2 className={`${mainColor} pt-2`}>Social</h2>
        <div>
          <a
            href="https://www.linkedin.com/in/danielsilvajobs/"
            rel="noreferrer"
            target="_blank"
            title="Daniel Linkedin profile"
          >
            <LinkedinIcon size={32} round={true} />
          </a>
        </div>
      </div>
      <Top10Posts />
    </Layout>
  );
};

export default IndexPage;
